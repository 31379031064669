import React, { useEffect, useState } from "react";
import { Button, Radio } from "@mui/material";
import classes from "./Poll.module.css";
const Poll = (props) => {
  //const { pollQuestion, setPollAns } = props;

  /*   const [question, setQuestion] = useState(null); */

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); //This will be removed in the final code.
  const [answer, setAnswer] = useState({});
  const [isAwaitingNext, setIsAwaitingNext] = useState(false);
  const [isPollEnded, setIsPollEnded] = useState(false);
  const eventId = "den783283cr4gn38nyg";
  const userId = "ASINL4BBodfwin"
  // Function to fetch the question
  /* const fetchQuestion = async () => {
    try {
      const response = await axios.get('API_ENDPOINT_TO_GET_QUESTION'); // Replace with your API endpoint
      // Assuming the response contains a field 'question' with the question data
      // and a field 'pollEnded' that indicates whether the poll has ended
      if (response.data.pollEnded) {
        setIsPollEnded(true);
        return;
      }
      setQuestion(response.data.question);
    } catch (error) {
      console.error(error);
    } finally {
      setIsAwaitingNext(true);
    }
  }; */

  const questions = [
    {
      id: 1,
      question: "Would you like to earn a million dollars?",
      options: ["Yes", "No"],
    },
    {
      id: 2,
      question: "Which cities are you currently in?",
      options: ["Bangalore", "Mumbai", "Delhi", "Chennai", "Indore"],
    },
    {
      id: 3,
      question: "How often do you want to come to office?",
      options: [
        "Twice a week",
        "Once a Week",
        "Twice a month",
        "Permanent Wfh",
      ],
    },
  ];

  const handleAnswerChange = (questionId, selectedAnswer) => {
    const resultObj = {
      questionId: questionId,
      selectedAnswer: selectedAnswer,
      eventId: eventId,
      userId: userId
    };
    setAnswer(resultObj);
  };

  const handleSubmit = (e) => {
    setIsAwaitingNext(true);
    e.preventDefault();
    try {
      //We have to get the info of poll's ending from the api response and not the length in the final code.
      if (currentQuestionIndex === questions.length - 1) {
        setIsPollEnded(true);
      }
      const resultJSON = JSON.stringify(answer);
      console.log("Question submitted with answer:", resultJSON);

      //We would remove this in the final code, since
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } catch (error) {
      console.error(error);
    } finally {
      //fetchQuestion();
    }
  };

  // Fetch the question on component mount
  /*   useEffect(() => {
    fetchQuestion();
  }, []); */

  useEffect(() => {
    let timeout;
    if (isAwaitingNext && !isPollEnded) {
      timeout = setTimeout(() => {
        setIsAwaitingNext(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isAwaitingNext]);

  //Instead of fetching it from a list, the current question would be fetched form the backend. This code will be removed.
  const currentQuestion = questions[currentQuestionIndex];

  return (
    <>
      {!isAwaitingNext && (
        <div className={classes.container}>
          <h2>Live Polls</h2>
          <div className={classes.questionBody}>
            <h3>{currentQuestion.question}</h3>
            {currentQuestion.options.map((option, index) => (
              <div className={classes.pollBody} key={index}>
                <Radio
                  id={`option-${index}`}
                  name={`option-${index}`}
                  value={option}
                  checked={answer.selectedAnswer === option}
                  onChange={(event) => {
                    handleAnswerChange(currentQuestion.id, event.target.value);
                  }}
                />
                <label htmlFor={`option-${index}`}>{option}</label>
              </div>
            ))}
          </div>

          <div className={classes.buttons}>
            <Button onClick={handleSubmit} variant="contained" color="success">
              Submit
            </Button>
          </div>
        </div>
      )}
      {isAwaitingNext && !isPollEnded && <h3>Awaiting next question</h3>}
      {isAwaitingNext && isPollEnded && (
        <h2>This poll has ended, thank you!</h2>
      )}
    </>
  );
};

export default Poll;
