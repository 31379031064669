import React from 'react'

import "./CreatePoll.css";
import Option from '../../../components/question/Option';

function onCreatePollHandler() {

}

const CreatePoll = (props) => {
    return <Option />
}

export default CreatePoll
