import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./Home.css";

import Navbar from "../../components/navbar/Navbar";
import About from "../about/About";
import Quiz from "../quiz/Quiz";
import UserProfile from "../userProfile/UserProfile";
import CreateQuiz from "../admin/createQuiz/CreateQuiz";
import CreatePoll from "../admin/createPoll/CreatePoll";
import Homepage from "../homepage/Homepage";
import Poll from "../polling/Poll";

const Home = () => {
  //  const type = "user";
  //  const type = "admin";
  const type = "admin";
  return (
    <>
      <Router>
        <Navbar type={type} />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/polling" element={<Poll />} />
          <Route path="/about" element={<About />} />
          <Route path="/userProfile" element={<UserProfile />} />
          <Route path="/createQuiz" element={<CreateQuiz />} />
          <Route path="/createPoll" element={<CreatePoll />} />
        </Routes>
      </Router>
    </>
  );
};

export default Home;
